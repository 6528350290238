import React from "react";

import womenBras from "../assets/images/shop-womens-bras-panties.jpg";
import menUnderwear from "../assets/images/shop-mens-underwear.jpg";

import { GENDER, MEN_BRANDS, MEN_STYLES, WOMEN_BRANDS, WOMEN_STYLES } from "../const";

const HomePage = () => {
  const handleStyle = (evt) => {
    console.log(evt.target.value,'style')
  }

  return (
    <>
      <div className="columns-2 gap-0 border-b-16 border-border-dark">
        <div className="w-full border-x-2 border-border-light">
          <a href="/women">
            <img className="w-full" src={womenBras} alt="" />
          </a>
        </div>
        <div className="w-full border-x-2 border-border-light">
          <a href="/men">
            <img className="w-full" src={menUnderwear} alt="" />
          </a>
        </div>
      </div>
      {/* <div className="border-b-20 border-primary"></div> */}
      <div className="border-y-20 border-primary">
        <div className="grid grid-cols-2 sm:gap-2 gap-0 my-2">
          <select
            id="small-select"
            name="small-select"
            className="my-1 block pl-2 pr-6 py-1 max-w-56 border border-gray-300 bg-white text-xs rounded-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            onChange={handleStyle}
          >
            <option>Women's styles</option>
            {
              WOMEN_STYLES.map((item, key) => {
                return <option key={key} value={item.value}>{item.label}</option>
              })
            }
          </select>
          <select
            id="small-select"
            name="small-select"
            className="my-1 block pl-2 pr-6 py-1 max-w-56 border border-gray-300 bg-white text-xs rounded-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          >
            <option>Men's styles</option>
            {
              MEN_STYLES.map((item, key) => {
                return <option key={key} value={item.value}>{item.label}</option>
              })
            }
          </select>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-0 border-b-20 border-primary">
        <div>
          <p className="border-r-4 border-border-light pl-2 bg-border-dark text-white">Shop by Brand - For Her</p>
          <div className="grid grid-cols-1 sm:grid-cols-2 border-r-4 border-border-light py-4 pl-2 text-xs">
            <ul>
              {
                WOMEN_BRANDS.LINE1.map((item, key) => {
                  return <li key={key}><a href={"/shop/brand/" + GENDER.WOMEN + "/" + item.value} className="text-indigo-800">{item.label}</a></li>;
                })
              }
            </ul>
            <ul>
              {
                WOMEN_BRANDS.LINE2.map((item, key) => {
                  return <li key={key}><a href={"/shop/brand/" + GENDER.WOMEN + "/" + item.value} className="text-indigo-800">{item.label}</a></li>;
                })
              }
            </ul>
          </div>
        </div>
        <div>
          <p className="bg-border-dark text-white pl-2">Shop by Brand - For Him</p>
          <div className="grid grid-cols-1 sm:grid-cols-2 py-4 pl-2 text-xs">
            <ul>
              {
                MEN_BRANDS.LINE1.map((item, key) => {
                  return <li key={key}><a href={"/shop/brand/" + GENDER.MEN + "/" + item.value} className="text-indigo-800">{item.label}</a></li>;
                })
              }
            </ul>
            <ul>
              {
                MEN_BRANDS.LINE2.map((item, key) => {
                  return <li key={key}><a href={"/shop/brand/" + GENDER.MEN + "/" + item.value} className="text-indigo-800">{item.label}</a></li>;
                })
              }
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default HomePage
