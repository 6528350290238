// import { GENDER } from "../index";

export const MEN_STYLES = [
    {
        label: 'Bikinis',
        value: 2 + '-Bikinis'
    },
    {
        label: 'Briefs',
        value: 2 + '-Briefs'
    },
    {
        label: 'Boxer Briefs',
        value: 2 + '-Boxer_Briefs'
    },
    {
        label: 'Boxer Shorts',
        value: 2 + '-Boxer_Shorts'
    },
    {
        label: 'Jockstraps',
        value: 2 + '-Jockstraps'
    },
    {
        label: 'Thongs',
        value: 2 + '-Thongs'
    },
    {
        label: 'Squarecut',
        value: 2 + '-Squarecut'
    },
    {
        label: 'T-Shirts',
        value: 2 + '-T-Shirts'
    },
    {
        label: 'Tank Tops',
        value: 2 + '-Tank_Tops'
    },
    {
        label: 'V-Neck',
        value: 2 + '-V-Neck'
    },
    {
        label: 'Crew Neck',
        value: 2 + '-Crew_Neck'
    },
    {
        label: 'Shapewear',
        value: 2 + '-Shapewear'
    },
    {
        label: 'Enhancement',
        value: 2 + '-Enhancement'
    }
]