import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import axios from "axios";
import Pagination from "../../components/pagination.jsx";
// import { WOMEN_BRANDS } from "../../const/brand/women.js";

import HeaderAdImg from "../../assets/images/CalvinkleinMportrait.jpg";
import ItemImg1 from "../../assets/images/calvin-klein-crew-undershirts.jpg";
import ItemImg2 from "../../assets/images/calvin-klein-v-tshirts.jpg";
import ItemImg3 from "../../assets/images/calvin-klein-v-undershirts.jpg";
// import { MEN_BRANDS } from "../../const/brand/men.js";

// const MenBrands = [...MEN_BRANDS.LINE1, ...MEN_BRANDS.LINE2];
const products = [
  {
    cover: ItemImg1,
    name: 'Calvin Klein',
    price: '$40'
  },
  {
    cover: ItemImg2,
    name: "Calvin Klein's panty",
    price: '$35'
  },
  {
    cover: ItemImg3,
    name: "Calvin Klein's panty",
    price: '$53'
  },
  {
    cover: ItemImg1,
    name: 'Calvin Klein',
    price: '$40'
  },
  {
    cover: ItemImg2,
    name: "Calvin Klein's panty",
    price: '$35'
  },
  {
    cover: ItemImg1,
    name: 'Calvin Klein',
    price: '$40'
  },
  {
    cover: ItemImg3,
    name: "Calvin Klein's panty",
    price: '$53'
  }
]

const ShopStylePage = () => {
  const { gender, style } = useParams();
  console.log(gender, 'gender')
  console.log(style, 'style')

  // const [products, setProducts] = useState([]);
  // const [brand, setBrand] = useState('');
  // const [style, setStyle] = useState('');
  // const [garmentType, setGarmentType] = useState('');

  // useEffect(() => {
  //   handleSearch();
  // }, [])

  // const handleSearch = () => {
  //   axios.get('http://localhost:8000/api/scrape/', {
  //     params: {
  //       brand,
  //       style
  //     }
  //   })
  //     .then(response => {
  //       setProducts(response.data);
  //     })
  //     .catch(error => {
  //       console.error("There was an error fetching the data!", error);
  //     });
  // };

  return (
    <>
      <div className="grid grid-cols-4 gap-0">
        {/* Brand List */}
        <div className="py-3 hidden sm:block">
          <p className="pl-2 py-2">His Brands</p>
          <div className="grid grid-cols-1 md:grid-cols-2 border-t-14 border-border-light pl-2 text-xs">
            <ul>
              {/* {
                WOMEN_BRANDS.LINE1.map((item, key) => {
                  return <li key={key} value={item.value}>{item.label}</li>;
                })
              } */}
            </ul>
            <ul>
              {/* {
                WOMEN_BRANDS.LINE2.map((item, key) => {
                  return <li key={key} value={item.value}>{item.label}</li>;
                })
              } */}
            </ul>
          </div>
        </div>
        <div className="col-span-4 sm:col-span-3 p-6">
          {/* Advertise content */}
          <div className="flex justify-between py-3 sm:block">
            <div className="flex justify-center">
              <div className="w-40 h-32 sm:w-64 sm:h-56 border broder-border-light">
                <img className="w-full h-full" src={HeaderAdImg} alt="" />
              </div>
            </div>
            <div className="flex justify-center sm:pt-3">
              <p className="text-xs text-border-light px-3 sm:px-20 text-center">Calvin Klein underwear is the original designer underwear with classic and sexy designs, often imitated.  Calvin Klein underwear makes headlines and so will you in your Calvin's.</p>
            </div>
          </div>
          {/* Border */}
          <div className="border-t-6 border-border-light w-7/12"></div>
          {/* Pagination */}
          <div className="py-3 flex justify-center">
            <Pagination />
          </div>
          {/* Select Box for Brand list */}
          <div>
            <select
              id="small-select"
              name="small-select"
              className="sm:hidden my-2 block pl-2 pr-6 py-1 max-w-56 border border-gray-300 bg-white text-xs rounded-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option>Men's Brands</option>
              {/* {
                MenBrands.map((item, key) => {
                  return <option key={key} value={item.value}>{item.label}</option>
                })
              } */}
            </select>
          </div>
          {/* Gird Content */}
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {
              products.map((item, index) => {
                return (
                  <div className="flex justify-center" key={index}>
                    <div className="w-36 min-w-36 max-w-36 sm:w-48 sm:min-w-48 sm:max-w-48">
                      <div className="flex justify-center">
                        <div className="border border-border-light w-full">
                          <img className="w-full h:40 sm:h-52" src={item.cover} alt='' />
                        </div>
                      </div>
                      <div className="text-xs text-primary">
                        <p>{item.name}</p>
                      </div>
                      <div className="text-xs text-border-dark">
                        <p>{item.price}</p>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default ShopStylePage
