// import { GENDER } from "../index";

export const WOMEN_STYLES = [
    // Bras
    {
        label: 'Wire-Free Bras',
        value: 1 + '-Wire-Free Bras'
    },
    {
        label: 'Sports Bras',
        value: 1 + '-Sports_Bras'
    },
    {
        label: 'T-Shirt Bras',
        value: 1 + '-T-Shirt_Bras'
    },
    {
        label: 'Minimizer Bras',
        value: 1 + '-Minimizer_Bras'
    },
    {
        label: 'Underwire Bras',
        value: 1 + '-Underwire_Bras'
    },
    {
        label: 'Strapless Bras',
        value: 1 + '-Strapless_Bras'
    },
    {
        label: 'Bralettes',
        value: 1 + '-Bralettes'
    },
    {
        label: 'Front-Close Bras',
        value: 1 + '-Front-Close_Bras'
    },
    {
        label: 'Push-Up Bras',
        value: 1 + '-Push-Up_Bras'
    },
    {
        label: 'Racerback Bras',
        value: 1 + '-Racerback_Bras'
    },
    {
        label: 'Nursing & Maternity',
        value: 1 + '-Nursing & Maternity'
    },
    {
        label: 'Mastectomy',
        value: 1 + '-Mastectomy'
    },
    {
        label: 'Balconette',
        value: 1 + '-Balconette'
    },
    {
        label: 'Plunge',
        value: 1 + '-Plunge'
    },
    {
        label: 'Lightly Lined Bras',
        value: 1 + '-Lightly_Lined_Bras'
    },
    // Panties
    {
        label: 'Thongs',
        value: 1 + '-Thongs'
    },
    {
        label: 'Hi-Cut Briefs',
        value: 1 + '-Hi-Cut Briefs'
    },
    {
        label: 'Briefs',
        value: 1 + '-Briefs'
    },
    {
        label: 'Bikinis',
        value: 1 + '-Bikinis'
    },
    {
        label: 'Crotchless',
        value: 1 + '-Crotchless'
    },
    {
        label: 'Boyshorts',
        value: 1 + '-Boyshorts'
    },
    {
        label: 'Cheeky',
        value: 1 + '-Cheeky'
    },
    {
        label: 'Hipster',
        value: 1 + '-Hipster'
    },
    {
        label: 'G-String',
        value: 1 + '-G-String'
    },
    {
        label: 'Shaping & Control',
        value: 1 + '-Shaping & Control'
    },
    {
        label: 'Seamless',
        value: 1 + '-Seamless'
    },
    {
        label: 'Sheer',
        value: 1 + '-Sheer'
    },
    {
        label: 'Multi-Packs',
        value: 1 + '-Multi-Packs'
    },
    {
        label: 'Leak Proof',
        value: 1 + '-Leak_Proof'
    },
    {
        label: 'Plus Size',
        value: 1 + '-Plus_Size'
    },
    // Shapewear
    {
        label: 'Shaping Bodysuits',
        value: 1 + '-Shaping_Bodysuits'
    },
    {
        label: 'Shaping Panties',
        value: 1 + '-Shaping_Panties'
    },
    {
        label: 'Slimming Camisoles',
        value: 1 + '-Slimming_Camisoles'
    },
    {
        label: 'Mid-Thigh Shapers',
        value: 1 + '-Mid-Thigh_Shapers'
    },
    {
        label: 'Shaping Leggings',
        value: 1 + '-Shaping_Leggings'
    },
    {
        label: 'High-Waisted Panties',
        value: 1 + '-High-Waisted_Panties'
    },
    {
        label: 'Longline Bras',
        value: 1 + '-Longline_Bras'
    },
    {
        label: 'Bridal Shapewear',
        value: 1 + '-Bridal_Shapewear'
    },
    {
        label: 'Back-Smoothing Bras',
        value: 1 + '-Back-Smoothing_Bras'
    },
    {
        label: 'Backless',
        value: 1 + '-Backless'
    }
]
