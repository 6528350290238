export const WOMEN_BRANDS = {
    LINE1: [
        {
            label: 'Adore Me',
            value: 'Adore_Me'
        },
        {
            label: 'Agent Provocateur',
            value: 'Agent_Provocateur'
        },
        {
            label: 'Alala',
            value: 'Alala'
        },
        {
            label: 'Amoena',
            value: 'Amoena'
        },
        {
            label: 'b.tempt’d',
            value: 'b.tempt’d'
        },
        {
            label: 'Bali',
            value: 'Bali'
        },
        {
            label: 'Bravado Designs',
            value: 'Bravado_Designs'
        },
        {
            label: 'Calvin Klein',
            value: 'Calvin_Klein'
        },
        {
            label: 'Carnival',
            value: 'Carnival'
        },
        {
            label: 'Chantelle',
            value: 'Chantelle'
        },
        {
            label: 'Commando',
            value: 'Commando'
        },
        {
            label: 'Cosabella',
            value: 'Cosabella'
        },
        {
            label: 'DearKate',
            value: 'DearKate'
        },
        {
            label: 'Dolce + Gabbana',
            value: 'Dolce + Gabbana'
        },
        {
            label: 'Dominique',
            value: 'Dominique'
        },
        {
            label: 'Dreamgirl',
            value: 'Dreamgirl'
        },
        {
            label: 'Eberjey',
            value: 'Eberjey'
        },
        {
            label: 'Elomi',
            value: 'Elomi'
        },
        {
            label: 'Eres',
            value: 'Eres'
        },
        {
            label: 'Fantasie',
            value: 'Fantasie'
        },
        {
            label: 'Felina',
            value: 'Felina'
        },
        {
            label: 'Fleur du Mal',
            value: 'Fleur_du_Mal'
        },
        {
            label: 'Flora',
            value: 'Flora'
        },
        {
            label: 'Free People',
            value: 'Free_People'
        },
        {
            label: 'Freya',
            value: 'Freya'
        },
        {
            label: 'Glamorise',
            value: 'Glamorise'
        },
        {
            label: 'Goddess',
            value: 'Goddess'
        },
        {
            label: 'Hanky Panky',
            value: 'Hanky_Panky'
        },
        {
            label: 'Hanes',
            value: 'Hanes'
        },
        {
            label: 'Hanro',
            value: 'Hanro'
        },
        {
            label: 'Hauty',
            value: 'Hauty'
        },
        {
            label: 'Holiday Lane',
            value: 'Holiday_Lane'
        },
        {
            label: 'Honeydew',
            value: 'Honeydew'
        },
        {
            label: 'Ingrid + Isabel',
            value: 'Ingrid + Isabel'
        },
    ],
    LINE2: [
        {
            label: 'Jezebel',
            value: 'Jezebel'
        },
        {
            label: 'Jockey',
            value: 'Jockey'
        },
        {
            label: 'Kiki De Montparnasse',
            value: 'Kiki_De_Montparnasse'
        },
        {
            label: 'Kindred Bravely',
            value: 'Kindred_Bravely'
        },
        {
            label: 'La Perla',
            value: 'La_Perla'
        },
        {
            label: 'Lauren Ralph Lauren',
            value: 'Lauren_Ralph_Lauren'
        },
        {
            label: 'Le Mystere',
            value: 'Le_Mystere'
        },
        {
            label: 'Lechery',
            value: 'Lechery'
        },
        {
            label: 'Leonisa',
            value: 'Leonisa'
        },
        {
            label: 'Lilyette',
            value: 'Lilyette'
        }, 
        {
            label: 'Linea Donatella',
            value: 'Linea_Donatella'
        },
        {
            label: 'Lively',
            value: 'Lively'
        },
        {
            label: 'Maidenform',
            value: 'Maidenform'
        },
        {
            label: 'MeMoi',
            value: 'MeMoi'
        },
        {
            label: 'Natori',
            value: 'Natori'
        },
        {
            label: 'Nike',
            value: 'Nike'
        },
        {
            label: 'Oh La La Cheri',
            value: 'Oh_La_La_Cheri'
        },
        {
            label: 'Olga',
            value: 'Olga'
        },
        {
            label: 'On Gossamer',
            value: 'On_Gossamer'
        },
        {
            label: 'Paramour',
            value: 'Paramour'
        },
        {
            label: 'Parfait',
            value: 'Parfait'
        },
        {
            label: 'Playtex',
            value: 'Playtex'
        },
        {
            label: 'Risque',
            value: 'Risque'
        },
        {
            label: 'Simone Perele',
            value: 'Simone_Perele'
        },
        {
            label: 'Spanx',
            value: 'Spanx'
        },
        {
            label: 'Splendid',
            value: 'Splendid'
        },
        {
            label: 'Stance',
            value: 'Stance'
        },
        {
            label: 'State of Day',
            value: 'State_of_Day'
        },
        {
            label: 'Stems',
            value: 'Stems'
        },
        {
            label: 'Vanity Fair',
            value: 'Vanity_Fair'
        },
        {
            label: 'Wacoal',
            value: 'Wacoal'
        },
        {
            label: 'Warners',
            value: 'Warners'
        },
        {
            label: 'Wolford',
            value: 'Wolford'
        },
        {
            label: 'ZooZatz',
            value: 'ZooZatz'
        }
    ],
}
