import { MEN_BRANDS } from "./brand/men";
import { WOMEN_BRANDS } from "./brand/women";
import { MEN_STYLES } from "./style/men";
import { WOMEN_STYLES } from "./style/women";

const GENDER = {
    WOMEN: 1,
    MEN: 2
}

export {
    GENDER,
    MEN_BRANDS,
    WOMEN_BRANDS,
    MEN_STYLES,
    WOMEN_STYLES
}